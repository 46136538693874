<template>
    <div>
        <Carousel :banner-list="bannerLists"/>
        <div class="search-box mainwidth">
            <a-row class="search-top" :gutter="24">
                <a-col :span="21">
                    <a-input-search v-model:value="chanpinmingcheng" enter-button style="height: 40px;" @search="getProductList" placeholder="请输入产品名称">
                    </a-input-search>
                </a-col>
                <a-col :span="3"> <a-button @click="serchshow = !serchshow"><template #icon>
                    <img style="width: 18px;margin-right: 5px" src="../assets/images/shaixuan.png" alt="">
                </template>分类筛选</a-button></a-col>
                <a-col :span="24" v-if="serchshow">
                    <ul class="search-con">
                        <li>
                            <p class="p-one">服务项目：</p>
                            <p class="p-two">
                                <span :class="{'sp-active': fuwuxiangmu === ''}" @click="fuwuxiangmu = ''">不限</span>
                                <span v-for="(serviceItem, si) in serviceList" :key="si" :class="{'sp-active': fuwuxiangmu == serviceItem.BIANMA}" @click="fuwuxiangmu = serviceItem.BIANMA">{{serviceItem.NAME}}</span>
                            </p>
                        </li>
                        <li>
                            <p class="p-one">贷款期限：</p>
                            <p class="p-two">
                                <span :class="{'sp-active': qixianfanwei === ''}" @click="qixianfanwei = ''">不限</span>
                                <span v-for="(dateItem, di) in dateList" :key="di" :class="{'sp-active': qixianfanwei == dateItem.BIANMA}" @click="qixianfanwei = dateItem.BIANMA">{{dateItem.NAME}}</span>
                            </p>
                        </li>
                        <li>
                            <p class="p-one">担保方式：</p>
                            <p class="p-two">
                                <span :class="{'sp-active': danbaofangshi === ''}" @click="danbaofangshi = ''">不限</span>
                                <span v-for="(methodItem, mi) in methodList" :key="mi" :class="{'sp-active': danbaofangshi == methodItem.BIANMA}" @click="danbaofangshi = methodItem.BIANMA">{{methodItem.NAME}}</span>
                            </p>
                        </li>
                        <li>
                            <p class="p-one">贷款额度：</p>
                            <p class="p-two">
                                <span :class="{'sp-active': edufanwei === ''}" @click="edufanwei = ''">不限</span>
                                <span v-for="(quotaItem, qi) in quotaList" :key="qi" :class="{'sp-active': edufanwei == quotaItem.BIANMA}" @click="edufanwei = quotaItem.BIANMA">{{quotaItem.NAME}}</span>
                            </p>
                        </li>
                    </ul>
                    <div class="search-con-btn-wrap">
                        <div style="background: #457DFE" @click="getProductList">确认筛选</div>
                        <div style="background: #cccccc;margin-left: 15px" @click="cancelSelect">取消筛选</div>
                    </div>
                </a-col>
            </a-row>
        </div>
      <div class="search-box mainwidth" >
        <div style="margin-top: 15px">
          <a-button @click="getProductList" >
            默认排序
            <div style="float: right">
              <div v-if="false" style="height: 8px">
                <UpOutlined />
              </div>
              <div v-else>
                <DownOutlined/>
              </div>
            </div>
          </a-button>

          <a-button @click="lowestInterestRate">
            <div style="float: right">
              <div v-if="false" style="height: 8px">
                <UpOutlined />
              </div>
              <div v-else>
                <DownOutlined/>
              </div>
            </div>
            最低利率排序</a-button>
<!--          <a-button @click="clearAll">-->
<!--            <div style="float: right">-->
<!--              <div v-if="false" style="height: 8px">-->
<!--                <UpOutlined />-->
<!--              </div>-->
<!--              <div v-else>-->
<!--                <DownOutlined/>-->
<!--              </div>-->
<!--            </div>-->
<!--            成功率排序</a-button>-->
          <a-button @click="releasTimeInterestRate">
            <div style="float: right">
              <div v-if="false" style="height: 8px">
                <UpOutlined />
              </div>
              <div v-else>
                <DownOutlined/>
              </div>
            </div>
            上线时间</a-button>
<!--          <a-button @click="clearFilters">-->
<!--            <div style="float: right">-->
<!--              <div v-if="false" style="height: 8px">-->
<!--                <UpOutlined />-->
<!--              </div>-->
<!--              <div v-else>-->
<!--                <DownOutlined/>-->
<!--              </div>-->
<!--            </div>-->
<!--            服务企业数量</a-button>-->
<!--          <a-button @click="clearAll">-->
<!--            <div style="float: right">-->
<!--              <div v-if="false" style="height: 8px">-->
<!--                <UpOutlined />-->
<!--              </div>-->
<!--              <div v-else>-->
<!--                <DownOutlined/>-->
<!--              </div>-->
<!--            </div>-->
<!--            综合放款时效</a-button>-->
<!--          <a-button @click="clearFilters">-->
<!--            <div style="float: right">-->
<!--              <div v-if="false" style="height: 8px">-->
<!--                <UpOutlined />-->
<!--              </div>-->
<!--              <div v-else>-->
<!--                <DownOutlined/>-->
<!--              </div>-->
<!--            </div>-->
<!--            按审批时效</a-button>-->
          <a-button @click="moneyStartDesc">
            <div style="float: right">
              <div v-if="false" style="height: 8px">
                <UpOutlined />
              </div>
              <div v-else>
                <DownOutlined/>
              </div>
            </div>
            放款额度</a-button>
        </div>
      </div>
        <div class="product-box mainwidth">
           <ul class="product-con">
               <li v-for="(item, i) in pList" :key="i">
                   <div class="item-left">
                       <img v-if="item.LOGO_ADDR != ''" :src="imgApi + item.LOGO_ADDR">
                       <img v-else src="../assets/images/prodct-con01.png">
                   </div>
                   <a-divider type="vertical" style="height:auto;margin:0 15px" />
                   <div class="item-right">
                       <div class="item-right-top">
                           <p>
                               {{item.PRODUCT_NAME}}
                               <!-- <img src="../assets/images/product-con02.jpg"> -->
                            </p>
                           <a-button @click="$router.push({ path: '/ProdutcDetails', query: { id: item.FINANCIALPRODUCTS_ID } })">查看详情</a-button>
                       </div>
                       <div class="item-right-bot">
                          <div class="item-right-bot-list">
                              <p>服务项目：<span class="sp-special">{{item.SERVICE_COLUMN}}</span></p>
                              <p>额度范围：<span class="sp-special">{{item.MONEY_START}}万元</span></p>
                              <p>担保方式：<span class="sp-special">{{item.GUARANTEE_METHOD}}</span></p>
                              <p>期限范围：<span class="sp-special">{{item.BETWEEN_MONTHS_S}}个月</span></p>
                          </div>

                          <div class="item-right-bot-list">
                              <p style="width: 440px">发行机构：<span>{{item.BANKNAME}}</span></p>
                              <p>累计申请：<span>{{item.LINK_COUNT}}次</span></p>
                          </div>
                           <p style="width: 100%">利率区间：<span>{{item.INTEREST_RATE_START}}</span></p>
                       </div>

                   </div>
               </li>
           </ul>
           <div class="page-box">
                <a-pagination show-quick-jumper v-model:current="page" :total="total" @change="onPage" />
            </div>
        </div>
    </div>
</template>

<script>
import {
    AppstoreAddOutlined,
  DownOutlined,
  UpOutlined
} from '@ant-design/icons-vue'
import Base from '@/api/base.js'
import Carousel from '../components/Carousel'

export default {
    components: {
        Carousel,
        AppstoreAddOutlined,
      DownOutlined,
      UpOutlined
    },
    data() {
        return {
            bannerLists: [require('../assets/images/proBanner.jpg'),require('../assets/images/proBanner.jpg'),require('../assets/images/proBanner.jpg')],
            pList:[],
            imgApi: Base.cs + '/',
            serchshow: false,
            page: 1,
            total: 0,
            serviceList: [],
            dateList: [],
            methodList: [],
            quotaList: [],
            fuwuxiangmu: '',
            chanpinmingcheng: '',
            qixianfanwei: '',
            edufanwei: '',
            danbaofangshi: '',
          lowest:"0",
          releasTime:"0",
          moneyStart:"0"
        }
    },
    methods: {
        onPage (pageNumber) {
            console.log(pageNumber)
            this.page = pageNumber
            this.getProductList()
        },
        getProductList () {
            this.pList = []
            this.$store.dispatch('getProductList', {
                showCount: 10,
                currentPage: this.page,
                SERVICE_COLUMN: this.fuwuxiangmu,
                PRODUCT_NAME: this.chanpinmingcheng,
                BETWEEN_MONTHS_S: this.qixianfanwei,
                MONEY_START: this.edufanwei,
                GUARANTEE_METHOD: this.danbaofangshi,
                 lowest:this.lowest,
              releasTime:this.releasTime,
              moneyStart:this.moneyStart
            }).then(res => {
                if (res.result === 'success') {
                    for (let i in res.varList) {
                        if (res.varList[i].LOGO_ADDR) {
                            if (res.varList[i].LOGO_ADDR.split('.')[1] != 'jpg'
                            && res.varList[i].LOGO_ADDR.split('.')[1] != 'png'
                            && res.varList[i].LOGO_ADDR.split('.')[1] != 'jpeg') {
                                res.varList[i].LOGO_ADDR = ''
                            }
                        } else {
                            res.varList[i].LOGO_ADDR = ''
                        }
                    }
                    this.pList = res.varList
                    this.total = res.page.totalResult
                } else {
                    this.pList = []
                    this.total = 0
                    this.page = 1
                }
                this.lowest = "0"
              this.releasTime = "0"
              this.moneyStart="0"
            })
        },
        getConditionList () {
            // 获取服务项目列表
            this.$store.dispatch('getServiceItemList', { DICTIONARIES_ID: '5e3766b8ee284c929c72cbfeba325b3b', tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.serviceList = res.list
                }
            })
            // 获取担保方式列表
            this.$store.dispatch('getServiceItemList', { DICTIONARIES_ID: '6c14cf3422324b6aaf7a8637394c9b89', tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.methodList = res.list
                }
            })
            // 获取期限范围列表
            this.$store.dispatch('getServiceItemList', { DICTIONARIES_ID: 'e7486ddcb4c143149deeecbe3ff9a58b', tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.dateList = res.list
                }
            })
            // 获取额度范围列表
            this.$store.dispatch('getServiceItemList', { DICTIONARIES_ID: 'c81a6c18aef04a58892753d289640b9f', tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.quotaList = res.list
                }
            })
        },
        cancelSelect(){
            this.fuwuxiangmu = ''
                this.qixianfanwei = ''
            this.danbaofangshi=''
            this.edufanwei=''
            this.getProductList()
        },
       //最低利率排序
      lowestInterestRate(){
        this.lowest = "1"
        this.getProductList ()
      },
      releasTimeInterestRate(){
          this.releasTime = "1"
         this.getProductList ()
      },
      moneyStartDesc(){
        this.moneyStart = "1"
        this.getProductList ()
      }
    },
    created () {
        this.fuwuxiangmu = this.$route.query.fwxm ? this.$route.query.fwxm : ''
        this.qixianfanwei = this.$route.query.rzqx ? this.$route.query.rzqx : ''
        this.danbaofangshi = this.$route.query.dbfs ? this.$route.query.dbfs : ''
        this.edufanwei = this.$route.query.rzje ? this.$route.query.rzje : ''
        this.getConditionList()
        this.getProductList()
    }
}
</script>
<style scoped lang="less">
.search-top ::v-deep(.ant-input){
  height: 40px;
  font-size: 16px;
}
.ant-input-affix-wrapper:hover {
    border-color: #3352c5;
    border-right-width: 1px !important;
}
.ant-input-affix-wrapper:focus {
    border-color: #3352c5;
    border-right-width: 1px !important;
}
.ant-input-affix-wrapper-focused{
    border-color: #3352c5;
}
.search-top ::v-deep(.ant-btn){
    padding:0 32px;
    color: #ffffff;
    width: 100%;
    height: 40px;
    background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
    background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
    background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
    background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
}
.item-right-top ::v-deep(.ant-btn){
    padding:0 42px;
    color: #ffffff;
    height: 40px;
    background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
    background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
    background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
    background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
}
.search-con-btn-wrap{
   display: flex;
    align-items: center;
    justify-content: center;
    height: 112px;
    div{
        cursor: pointer;
        border-radius: 3px;
        width: 155px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 16px;
        color: #fff;
    }
}
// .page-box ::v-deep(.ant-pagination-item):hover {
//     border-color: @color-blue;
//     transition: all 0.3s;
//     a{
//         color: @color-blue;
//     }
// }
// .page-box ::v-deep(.ant-pagination-item-active){
//     border-color: @color-blue;
//     a{
//         color: @color-blue;
//     }
// }
</style>
<style lang="less" scoped>
.search-box{
    margin: 0 auto!important;
    .search-top{
        margin-left: 0!important;
        margin-right: 0!important;
        margin-top: 30px;
        padding: 15px 10px;
        background-color:@color-ff;
        border-radius: 5px;
        box-shadow: 0px 0px 10px #929292;
        position: relative;
        font-size: @font-xl;
        .search-con{
            margin-top: 15px;
            margin-bottom: 0;
            li{
                font-size: @font-lg;
                padding:10px 0px;
                border-bottom: @border-base;
                display: flex;
                justify-content: flex-start;
                p{
                    margin-bottom: 0;
                    font-size: @font-lg;
                }
                .p-one{
                    width: 120px;
                    padding-top: 2px;
                    margin:5px 10px;
                }
                .p-two{
                    width: 1156px;
                    span{
                        display: inline-block;
                        padding: 2px 20px;
                        margin:5px 10px;
                        border-radius: 2px;
                        cursor: pointer;
                        transition: all .1s ease;
                        -moz-transition:all .1s ease; /* Firefox 4 */
                        -webkit-transition:all .1s ease; /* Safari 和 Chrome */
                        -o-transition:all .1s ease; /* Opera */
                    }
                    span:hover{
                        color: @color-ff;
                        background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
                        background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
                        background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
                        background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
                    }
                    .sp-active{
                        color: @color-ff;
                        background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
                        background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
                        background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
                        background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
                    }
                }
            }
        }
    }
    .search-top::before{
        content: '';
        position: absolute;
        left: -3px;
        top: 15px;
        width: 6px;
        height: 40px;
        background: -webkit-linear-gradient(to top,@color-blue,@color-purple); /* Safari 5.1-6.0 */
        background: -o-linear-gradient(to top,@color-blue,@color-purple); /* Opera 11.1-12.0 */
        background: -moz-linear-gradient(to top,@color-blue,@color-purple); /* Firefox 3.6-15 */
        background: linear-gradient(to top,@color-blue,@color-purple); /* 标准语法 */
    }
    .search-top::after{
        content: '';
        position: absolute;
        right: -3px;
        top: 15px;
        width: 6px;
        height: 40px;
        background: -webkit-linear-gradient(to top,@color-blue,@color-purple); /* Safari 5.1-6.0 */
        background: -o-linear-gradient(to top,@color-blue,@color-purple); /* Opera 11.1-12.0 */
        background: -moz-linear-gradient(to top,@color-blue,@color-purple); /* Firefox 3.6-15 */
        background: linear-gradient(to top,@color-blue,@color-purple); /* 标准语法 */
    }

}
.product-box{
    .product-con{
        padding: 20px;
        margin-top: 9px;
        margin-bottom: 30px;
        background-color: @color-ff;
        border-radius: 5px;
        box-shadow: 0px 0px 10px #929292;
        li{
            display: flex;
            justify-content: space-between;
            padding: 20px 0;
            border-bottom: @border-base;
            .item-left{
                width: 356px;
                img{
                    width: 100%;
                    height: 200px;
                }
            }
            .item-right{
                width: 880px;
                .item-right-top{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 10px;
                    p{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 0;
                        font-size: 25px;
                        font-weight: bold;
                        img{
                            margin-left: 20px;
                            width: 150px;
                            height: 40px;
                        }
                    }
                }
                .item-right-bot{
                    border-top: @border-ea;
                    padding-top: 10px;
                    .item-right-bot-list{
                        display: flex;
                        justify-content: start;
                        flex-wrap: wrap;
                    }
                    p{
                        width: 220px;
                        padding:5px;
                        margin-bottom: 0;
                        font-size: @font-lg;
                        line-height: 30px;
                        .sp-special{
                            color: @color-blue;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
        li:first-child{
            padding-top: 0;
        }
    }
    .page-box{
        padding: 15px 20px;
        background-color: @color-ff;
        border-radius: 5px;
        box-shadow: 0px 0px 10px #929292;
        margin-bottom: 30px;
        position: relative;
        .ant-pagination{
            display: flex;
            justify-content: center;

        }
    }
    .page-box::before{
        content: '';
        position: absolute;
        left: -3px;
        top: 11px;
        width: 6px;
        height: 40px;
        background: -webkit-linear-gradient(to top,@color-blue,@color-purple); /* Safari 5.1-6.0 */
        background: -o-linear-gradient(to top,@color-blue,@color-purple); /* Opera 11.1-12.0 */
        background: -moz-linear-gradient(to top,@color-blue,@color-purple); /* Firefox 3.6-15 */
        background: linear-gradient(to top,@color-blue,@color-purple); /* 标准语法 */
    }
    .page-box::after{
        content: '';
        position: absolute;
        right: -3px;
        top: 11px;
        width: 6px;
        height: 40px;
        background: -webkit-linear-gradient(to top,@color-blue,@color-purple); /* Safari 5.1-6.0 */
        background: -o-linear-gradient(to top,@color-blue,@color-purple); /* Opera 11.1-12.0 */
        background: -moz-linear-gradient(to top,@color-blue,@color-purple); /* Firefox 3.6-15 */
        background: linear-gradient(to top,@color-blue,@color-purple); /* 标准语法 */
    }

}
</style>
